import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import { AccordionList } from 'kh-common-components';

function FrequentAnswerQuestions (props) {

	const showHeading = props.hideHeading ? false : true;

	return (
		<>
			<LayoutPanel background={props.panelBackground}
                     	 padding={props.panelPadding} 
                     	 {...props}>
            	<div className="container">
                	<div className="row mer-panel">
                		<div className="col-12 col-sm-10 col-lg-8 col-xl-7 content">
							{ showHeading && 
		                		<h2 className="text-center">Frequently asked questions</h2>
							}
	                		<AccordionList 
	                			listData={props.data}
								settings={props.settings}
	                		/>
							{props.description}
		                </div>
                	</div>
                </div>
            </LayoutPanel>
		</>
	);
}

export default FrequentAnswerQuestions;
