import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';

function LpgProductHighlight (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 content text-center">
                        <h2>Why choose Kleenheat?</h2>
                    </div>
                </div>
                <div className="row justify-content-lg-between justify-content-center mer-text--size-lg">
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-wa.svg"
                                    alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">WA born and bred</h5>
                                <p>We've been at home in WA for over 65 years.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-piggybank.svg"
                                    alt="Flowmoji piggy bank"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">Great value gas</h5>
                                <p>Enjoy added value thanks to our partnership with RAC.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-delivery.svg"
                                    alt="Flowmoji delivery truck"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">Local delivery network</h5>
                                <p>We deliver to more places, more often across WA.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default LpgProductHighlight;