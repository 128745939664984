import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from 'components/layout/layoutPanel';
import heroBanner from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import LpgProductHighlight from 'components/cards/LpgProductHighlight';
import Image from 'components/common/Image';
import Faq from "components/common/Faq";
import faqRacLpg from 'constants/components/accordion-list/rac-lpg-faq';
import { modalContents } from "constants/modal-content";
import { LinkModal } from "kh-common-components";

function RacLpgPage() {
	
	return (
		<>
			<SEO title="Exclusive Kleenheat offer for RAC Members | Kleenheat" 
                 titleBespoke="true"
            	 description="RAC members can enjoy bigger savings on their natural gas with Kleenheat."
                 meta={[{name:"robots", content:'noindex'}]}
        	/>
			<Header />
			<LayoutMain>
				<LayoutPanel theme="mer-theme--cyan"
							 background="mer-bg--primary-cyan"
							 padding="mer-py-lg">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							<div className="mer-panel__images col-10 col-sm-6 col-lg-5 col-xl-4 my-0 pt-0">
								<img src={heroBanner} 
									  className="mer-panel__image kleenheat-rac-logo"
									  alt="RAC logo" />
							</div>

							<div className="col-12 col-sm-10 col-lg-8 col-xl-7 text-center mer-text--size-lg py-0">
								<h1 className="my-2">Save $20 off your second order of 45kg LPG cylinders each year.<sup>^</sup></h1>
							</div>
						</div>
						<div className="row mer-panel align-items-center justify-content-around">
							<div className="col-12 col-sm-5 col-lg-4 col-xl-3 text-center mer-text--size-lg py-0">
								<div className="mer-panel__actions justify-content-center">
									<a href="/sign-up"
									   title="Go to natural gas page"
									   className="mer-button mer-button--primary d-flex">
									   Sign up & order
									</a>
									<a href="#info"
									   title="Go to natural gas page"
									   className="mer-button mer-button--secondary d-flex mt-3">
									   More info
									</a>
								</div>
                                <p>
                                    <small className="text-center">
										<LinkModal 
											modalContents={modalContents}
											useModal="true"
											title="Residential LPG 45kg cylinder offer"
											linkClass="mer-text--no-wrap"
											displayContent={<span><sup>^</sup>Terms and conditions apply</span>}
											modalTitle="Residential LPG 45kg cylinder offer"
											modalContentName='rac-lpg-terms'
											okayButtonLabel="Okay"
										/>
									</small>
                                </p>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<LpgProductHighlight panelBackground="mer-bg--ui-light mer-border--bottom mer-border--color-gs-lighter"
					                 panelPadding="mer-pt-lg" />

				<section id="info"
					className="mer-theme--light mer-bg--gs-grey-lighter mer-py-ju mer-border--bottom mer-border--color-gs-lighter">
					<div className="container">

						<div className="row justify-content-center">
							<div className="col-10 col-sm-8 col-md-7 mb-3">
								<div className="content text-center">
									<h3>How the RAC member discount works</h3>
									<p>Simply order a 45kg LPG cylinder for your home and enjoy $20 off your following cylinder order.<sup>^</sup></p>
                                    <p>This offer is redeemable once every 12 months, and your RAC member number must be linked to your account when placing your order.<sup>^</sup></p>
									<p>
										<small>
											<LinkModal 
												modalContents={modalContents}
												useModal="true"
												title="Residential LPG 45kg cylinder offer"
												linkClass="mer-text--no-wrap"
												displayContent={<span><sup>^</sup>Terms and conditions apply.</span>}
												modalTitle="Residential LPG 45kg cylinder offer"
												modalContentName='rac-lpg-terms'
												okayButtonLabel="Okay"
											/>
										</small>
									</p>
								</div>
							</div>
						</div>

						<div className="row justify-content-around align-items-center">
							<div className="col-10 col-sm-7 col-md-5 col-lg-4 mr-xl-n5 ml-xl-5 col-xl-3 align-items-stretch px-0">
								<div className="row mer-card mer-card--height-100 mdc-card mer-plan-card align-items-center">
									<div className="col-11 px-0">
										<h3 className="col-12 text-center my-0">New customer?</h3>
										<div className="col-8 mx-auto">
											<Image className="mer-panel__image"
												src="/icons/flowmoji/flowmoji-checkburst.svg"
												alt="natural gas discount" /> 
										</div>
										<div className="col-12 px-0 content text-center">
											<p className="px-0">Provide your RAC membership number when you sign up online.</p>
										</div>
									</div>
									<div className="col-12 p-0 mer-border--top mer-border--color-gs-lighter">
										<div className="col-11 px-0 mx-auto">
											<h4 className="my-0">
												<a href="/sign-up"
													title="Sign up online"
													className="mer-button mer-button--primary d-block text-center">
														<span className="mer-color--gs-white">Sign up online</span>
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>

							<div className="col-10 col-sm-7 col-md-5 col-lg-4 ml-xl-n5 mr-xl-5 col-xl-3 align-items-stretch px-0">
								<div className="row mer-card mer-card--height-100 mdc-card mer-plan-card align-items-center">
									<div className="col-11 px-0">
										<h3 className="col-12 text-center my-0">Existing customers</h3>
										<div className="col-8 mx-auto my-n2">
											<Image className="mer-panel__image"
												src="/icons/flowmoji/flowmoji-cylinder-trolley.svg"
												alt="Flowmoji cylinder trolley"
												style={{transform: 'scale(0.9)'}} />
										</div>
										<div className="col-12 px-0 content text-center">
											<p className="px-0">Link your RAC membership number to your account when you place an online order.</p>
										</div>
									</div>
									<div className="col-12 p-0 mer-border--top mer-border--color-gs-lighter">
										<div className="col-11 px-0 mx-auto">
											<h4 className="my-0">
												<a href="/lpg/order-online"
													title="Order online"
													className="mer-button mer-button--primary d-block text-center">
													<span className="mer-color--gs-white">Order online</span>
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row justify-content-around align-items-center">
							<p className="col-12 text-center mt-4 pt-4 pb-0 mb-0 mer-text--size-lg">
								If you're purchasing your LPG through a participating Kleenheat dealer, you’ll first need to <a href="/rac/lpg/dealer-customers" title="Register to receive credit" className="mer-text--no-wrap">register</a> to receive your $20 credit.
							</p>
						</div>
					</div>
				</section>

                <Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqRacLpg} />

                <LayoutPanel theme="mer-theme--cyan"
							 background="mer-bg--primary-cyan"
							 padding="mer-py-lg pt-5"
							 id="switch">
					<div className="container">
                        <div className="row justify-content-center align-items-middle">
                            <div className="col-12 col-sm-10 col-lg-8 mer-text--align-center mer-text--size-lg">
                                <h3 className="my-0">Switch to Kleenheat today</h3>
								<p>Sign up to access your exclusive RAC member discount.</p>
								<div className="col-12 col-sm-4 col-md-3 mer-panel__actions mer-button-group flex-column mx-auto mt-2">
									<a href="/lpg/sign-up"
										title="Go to natural gas page"
										className="mer-button mer-button--primary">
										Sign up
									</a>
								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

			</LayoutMain>
			<Footer />
		</>
	)
}

export default RacLpgPage;